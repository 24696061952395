<script>
    import { mapActions, mapGetters } from "vuex";
    import xml2js from 'xml2js';
    import { externalScript } from "../../assets/external";
    import Rates from '../Shared/Rates.vue';

    export default {
        components: {
            Rates 
        },
        data() {
            return {
                setActiveTab: 1,
                amount: 10000,
                amountPercent: 2,
                months: 12,
                count: 0,
                selected: 0,
                needPostSelected: 0,
                accountTypeSelected: 0,
                accountTypePostSelected: 0,
                mediaURL: '',
                Rates: []
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            nextPost() {
                this.count += 1;
                if (this.count + 1 > this.getBannerPosts.length)
                    this.count = 0
            },
            previousPost() {
                this.count -= 1;
                if (this.count < 0)
                    this.count = this.getBannerPosts.length - 1
            },
            ActiveTab(i) {
                this.amount = 10000,
                this.amountPercent = 2,
                this.months = 12,
                    this.setActiveTab = i;

                setTimeout(() => externalScript.reloadLanguage(), 150);
            },
            parseXML(data) {
                return new Promise(resolve => {
                    var i = "";
                    var arr = [],
                        parser = new xml2js.Parser(
                            {
                                trim: true,
                                explicitArray: true
                            });
                    parser.parseString(data, function (err, result) {
                        var obj = result["gesmes:Envelope"].Cube[0].Cube[0];

                        for (i in obj.Cube) {
                            var item = obj.Cube[i];
                            if (item.$.currency == "USD" || item.$.currency == "CHF" || item.$.currency == "GBP" || item.$.currency == "CAD") {
                                arr.push({
                                    currency: item.$.currency,
                                    rate: item.$.rate,

                                });
                            }
                        }
                        resolve(arr);
                    });
                });
            }
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getBanner() {
                return this.getCategories.find(x => x.slug === 'banner')
            },
            getYourBank() {
                return this.getCategories.find(x => x.slug === 'banksection')
            },
            getNeedSection() {
                return this.getCategories.find(x => x.slug === 'needsection')
            },
            getElectronicSection() {
                return this.getCategories.find(x => x.slug === 'electronic-section')
            },
            getDepositsSection() {
                return this.getCategories.find(x => x.slug === 'deposits-section')
            },
            getBannerPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getBanner?.id).slice(0, 3);
            },
            getYourBankPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getYourBank?.id)
            },
            getYourNeedsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getNeedSection?.id)
            },
            getElectricPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getElectronicSection?.id)
            },
            getDepositPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getDepositsSection?.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
            calculateKestiMujor() {
                var monthlyInterestRate = this.amountPercent / 12 / 100;

                var monthlyPayment = (this.amount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, - this.months));
                monthlyPayment = monthlyPayment.toFixed(2);

                var formatNumber = n => n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (monthlyPayment.toString().includes(".")) {
                    const decimalPos = monthlyPayment.indexOf(".");
                    let leftSide = monthlyPayment.substring(0, decimalPos);
                    let rightSide = monthlyPayment.substring(decimalPos);

                    leftSide = formatNumber(leftSide);
                    rightSide = formatNumber(rightSide);
                    rightSide = rightSide.substring(0, 2);

                    monthlyPayment = `${leftSide}.${rightSide} €`;
                } else {
                    monthlyPayment = formatNumber(monthlyPayment);
                    monthlyPayment = `${monthlyPayment}.00 €`;
                }

                return monthlyPayment;
            },
            calculateTotalInteres() {
                const monthlyPayments = this.calculateKestiMujor;
                const numericValue = parseFloat(monthlyPayments.replace(/[^\d.-]/g, ''));

                var monthlyPayment = ((numericValue * this.months)).toFixed(2);

                var formatNumber = n => n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (monthlyPayment.toString().includes(".")) {
                    const decimalPos = monthlyPayment.indexOf(".");
                    let leftSide = monthlyPayment.substring(0, decimalPos);
                    let rightSide = monthlyPayment.substring(decimalPos);

                    leftSide = formatNumber(leftSide);
                    rightSide = formatNumber(rightSide);
                    rightSide = rightSide.substring(0, 2);

                    monthlyPayment = `${leftSide}.${rightSide} €`;
                } else {
                    monthlyPayment = formatNumber(monthlyPayment);
                    monthlyPayment = `${monthlyPayment}.00 €`;
                }

               
                return monthlyPayment; 
            },
            interesiBruto() {
                var monthlyPayment = this.amount * ((this.amountPercent / 100) / 12) * this.months;

                monthlyPayment = monthlyPayment.toFixed(2).toString();

                var formatNumber = n => n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (monthlyPayment.toString().includes(".")) {
                    const decimalPos = monthlyPayment.indexOf(".");
                    let leftSide = monthlyPayment.substring(0, decimalPos);
                    let rightSide = monthlyPayment.substring(decimalPos);

                    leftSide = formatNumber(leftSide);
                    rightSide = formatNumber(rightSide);
                    rightSide = rightSide.substring(0, 2);

                    monthlyPayment = `${leftSide}.${rightSide} €`;
                } else {
                    monthlyPayment = formatNumber(monthlyPayment);
                    monthlyPayment = `${monthlyPayment}.00 €`;
                }


                return monthlyPayment; 

            },
            interesiNeto() {
                var monthlyPayment1 = this.amount * ((this.amountPercent / 100) / 12) * this.months;

                var monthlyPayment = monthlyPayment1 * 0.9;

                monthlyPayment = monthlyPayment.toFixed(2).toString();

                var formatNumber = n => n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (monthlyPayment.toString().includes(".")) {
                    const decimalPos = monthlyPayment.indexOf(".");
                    let leftSide = monthlyPayment.substring(0, decimalPos);
                    let rightSide = monthlyPayment.substring(decimalPos);

                    leftSide = formatNumber(leftSide);
                    rightSide = formatNumber(rightSide);
                    rightSide = rightSide.substring(0, 2);

                    monthlyPayment = `${leftSide}.${rightSide} €`;
                } else {
                    monthlyPayment = formatNumber(monthlyPayment);
                    monthlyPayment = `${monthlyPayment}.00 €`;
                }


                return monthlyPayment; 
            },
            currentLanguage() {
                let name = "lang=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        async mounted() {
            await this.fetchCategories();
            this.accountTypeSelected = this.getAccountEmployeeSection?.id;
            await this.fetchPosts();
            await this.fetchMedias();
            externalScript.reloadLanguage();
            document.getElementById("preloader").style.display = 'none';

            setInterval(() => this.nextPost(), 5000);
        },
    };
</script>

<template>
    <div class="home">
        <div class="banner-container" id="banner">
            <div class="banner">
                <div class="choose-slide">
                    <div v-for="(item,index) in getBannerPosts" :key="item.id" class="circle" :class="count === index ? 'selected' : ''" @click="count = index"></div>
                </div>
                <div class="first">
                    <div class="content">
                        <h1 v-html="currentLanguage == 'sq' ? getBannerPosts[count]?.meta._sq_post_title : (currentLanguage == 'en' ? getBannerPosts[count]?.meta._en_post_title : getBannerPosts[count]?.meta._sr_post_title)"></h1>
                        <div class="description" v-html="currentLanguage == 'sq' ? getBannerPosts[count]?.meta._sq_post_content : (currentLanguage == 'en' ? getBannerPosts[count]?.meta._en_post_content : getBannerPosts[count]?.meta._sr_post_content)"></div>
                        <router-link :to="'individual/post/' + this.getBannerPosts[count]?.id">
                            <button sq="Më shumë" en="More" sr="Više">
                            </button>
                        </router-link>
                    </div>
                    <div class="slider-counter">
                        <div class="counter">
                            <p>{{0}}{{(count+1)}}</p>
                            <div class="breakline"></div>
                            <p>{{0}}{{getBannerPosts.length}}</p>
                        </div>
                        <div class="switch-post">
                            <img src="../../assets/images/next.svg" @click="nextPost" alt="Next Icon" />
                            <img src="../../assets/images/previous.svg" @click="previousPost" alt="Previous Icon" />
                        </div>
                    </div>
                </div>
                <div class="media">
                    <img :src="getPostMedia(getBannerPosts[count]?.featured_media)" alt="Foto e njës së PriBank" />
                </div>
            </div>
        </div>

        <div class="yourneeds-container">
            <div class="yourneeds">
                <div class="title">
                    <h1 class="yourneeds-title"
                        sq="Bëhuni klient i PriBank dhe kurseni kohën tuaj me shërbimet tona"
                        en="Become a customer of PriBank and save your time with our services"
                        sr="Postanite korisnik PriBank-a i uštedite vreme uz naše usluge"></h1>
                </div>
                <div class="info">
                    <div class="needs-section">
                        <div class="media">
                            <img :src="getPostMedia(getYourNeedsPosts[needPostSelected]?.featured_media)" alt="Foto e shërbimit të PriBank" />
                        </div>
                        <div class="needs-list">
                            <div v-for="(item,index) in getYourNeedsPosts" :key="item.id" class="needs-post" @click="needPostSelected = index" @mouseover="needPostSelected = index" @mouseleave="needPostSelected = index">
                                <div class="title">
                                    <h1 @click="needPostSelected = index" :sq="item?.meta._sq_post_title" :en="item?.meta._en_post_title" :sr="item?.meta._sr_post_title"></h1>
                                </div>
                            </div>
                        </div>
                        <div class="description-container">
                            <div class="description">
                                <div class="title">
                                    <div v-show="needPostSelected == 0">
                                        <h1 :sq="getYourNeedsPosts[0]?.meta._sq_post_title"
                                            :en="getYourNeedsPosts[0]?.meta._en_post_title"
                                            :sr="getYourNeedsPosts[0]?.meta._sr_post_title">
                                        </h1>
                                    </div>
                                    <div v-show="needPostSelected == 1">
                                        <h1 :sq="getYourNeedsPosts[1]?.meta._sq_post_title"
                                            :en="getYourNeedsPosts[1]?.meta._en_post_title"
                                            :sr="getYourNeedsPosts[1]?.meta._sr_post_title">
                                        </h1>
                                    </div>
                                    <div v-show="needPostSelected == 2">
                                        <h1 :sq="getYourNeedsPosts[2]?.meta._sq_post_title"
                                            :en="getYourNeedsPosts[2]?.meta._en_post_title"
                                            :sr="getYourNeedsPosts[2]?.meta._sr_post_title">
                                        </h1>
                                    </div>
                                </div>
                                <div class="content">
                                    <div v-show="needPostSelected == 0">
                                        <p :sq="getYourNeedsPosts[0]?.meta._sq_post_excerpt"
                                           :en="getYourNeedsPosts[0]?.meta._en_post_excerpt"
                                           :sr="getYourNeedsPosts[0]?.meta._sr_post_excerpt">
                                        </p>
                                    </div>
                                    <div v-show="needPostSelected == 1">
                                        <p :sq="getYourNeedsPosts[1]?.meta._sq_post_excerpt"
                                           :en="getYourNeedsPosts[1]?.meta._en_post_excerpt"
                                           :sr="getYourNeedsPosts[1]?.meta._sr_post_excerpt">
                                        </p>
                                    </div>
                                    <div v-show="needPostSelected == 2">
                                        <p :sq="getYourNeedsPosts[2]?.meta._sq_post_excerpt"
                                           :en="getYourNeedsPosts[2]?.meta._en_post_excerpt"
                                           :sr="getYourNeedsPosts[2]?.meta._sr_post_excerpt">
                                        </p>
                                    </div>
                                </div>
                                <router-link :to="'individual/post/' + getYourNeedsPosts[needPostSelected]?.id">
                                    <button sq="Më shumë" en="More" sr="Više">
                                    </button>
                                </router-link>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="borrowing-container">
                    <div class="title">
                        <h1 class="borrowing-title"
                            sq="Kurseni dhe përfitoni nga mjetet tuaja"
                            en="Save and get the most out of your resources"
                            sr="Uštedite i iskoristite sve svoje resurse"></h1>
                    </div>
                    <div class="borrowing-info">
                        <div class="borrowing-section">
                            <div class="borrowing-post" v-for="(item,index) in getDepositPosts" :key="item.id">
                                <router-link :to="'individual/post/' + getDepositPosts[index].id" style="text-decoration: none; color: black;">
                                    <div class="media">
                                        <img :src="getPostMedia(getDepositPosts[index]?.featured_media)" alt="Foto e llojit të depozitës" />
                                    </div>
                                    <div class="title">
                                        <h1 :sq="item?.meta._sq_post_title" :en="item?.meta._en_post_title" :sr="item?.meta._sr_post_title ">
                                        </h1>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="yourbank-container">
            <div class="yourbank">
                <div class="title">
                    <h1 class="yourbank-title" sq="PriBank, në çdo kohë për ju!" en="PriBank, at any time for you!" sr="PriBank, u bilo koje vreme za Vas!">
                    </h1>
                </div>
                <div class="info">
                    <div class="bank-section">
                        <div v-for="(item, index) in getYourBankPosts" :key="item.id" class="bank-post">
                            <div v-if="item.id ==44">
                                <router-link :to="'/individual/bank-branches'">
                                    <div class="media">
                                        <img :src="getPostMedia(getYourBankPosts[index]?.featured_media)" alt="Bank-Branches" />
                                    </div>
                                </router-link>
                                <div class="title">
                                    <h1>{{ item?.meta._sq_post_title }}</h1>
                                </div>
                            </div>

                            <div v-if="item.id ==47">
                                <a :href="`tel:${item.meta._sq_post_title}`" aria-label="Phone number">
                                    <div class="media">
                                        <img :src="getPostMedia(getYourBankPosts[index]?.featured_media)" alt="Phone" />
                                    </div>
                                </a>
                                <div class="title">
                                    <h1>{{ item?.meta._sq_post_title }}</h1>
                                </div>
                            </div>

                            <div v-if="item.id ==51">
                                <a :href="`mailto:${item?.meta._sq_post_title}`" aria-label="Pribank Mail">
                                    <div class="media">
                                        <img :src="getPostMedia(getYourBankPosts[index]?.featured_media)" alt="Mail" />
                                    </div>
                                </a>
                                <div class="title">
                                    <h1>{{ item?.meta._sq_post_title }}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="calculator-container" style="margin-top: 10%;">
            <div style="text-align: center;">
                <h2 style="font-size: 30px; font-weight: 300; line-height: 36px; letter-spacing: 0em;" 
                    sq="Kalkulatori i kredisë"
                    en="PriBank is your partner for realizing your wishes"
                    sr="PriBank je vaš partner za ostvarivanje vaših želja">
                </h2>
            </div>

            <div style="padding-top: 66px;" id="CalculatorMainContainer">
                <div id="CalculatorWrapper">
                    <div id="leftPane">
                        <div>
                            <div style="display: flex; justify-content: center; gap: 30px; padding-bottom: 50px;">
                                <div :class="[setActiveTab == 1 ? 'active4' : 'a']" @click="ActiveTab(1)" style="display: flex; align-items :end; justify-content: center; height: 70px; cursor: pointer;" sq="KALKULATORI I KREDISË" en="LOAN CALCULATOR" sr="KALKULATOR KREDITA"></div>
                                <div :class="[setActiveTab == 2 ? 'active4' : 'a']" @click="ActiveTab(2)" style="display: flex; align-items: end; justify-content: center; height: 70px; cursor: pointer;" sq="KALKULATORI I DEPOZITËS" en="LOAN CALCULATOR" sr="KALKULATOR KREDITA"></div>
                            </div>
                            <div v-if="setActiveTab == 1">
                                <div style="display: flex; justify-content: space-between; align-items: center; gap: 50px;" class="CalcInfo">
                                    <div style="width: 300px;" class="inpWrp">
                                        <p style="color: #5c299e; font-weight: bold;" sq="Shuma e kredisë së kerkuar" en="Amount of loan" sr="Iznos traženog kredita" ></p>
                                        <input type="range" min="500" max="1000000" style="width: 300px;" v-model="amount" />
                                    </div>
                                    <div class="wrp" style="width: 150px;">
                                        <input id="amount" style="color: #5F4393; font-size: 15px; font-weight: 600" v-model="amount">
                                        <span>€</span>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: space-between; align-items: center; gap: 50px; margin-top: 20px; margin-bottom: 20px;" class="CalcInfo">
                                    <div class="inpWrp">
                                        <p style="color: #5c299e; font-weight: bold;" sq="Norma e interesit" en="Interest rate" sr="Kamatna stopa"></p>
                                        <input type="range" min="2" max="20" style="width: 300px;" v-model="amountPercent" />
                                    </div>
                                    <div class="wrp" style="width: 150px;">
                                        <input id="amount" style="color: #5F4393; font-size: 15px; font-weight: 600" v-model="amountPercent">
                                        <span>%</span>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: space-between; align-items: center; gap: 50px;" class="CalcInfo">
                                    <div class="inpWrp">
                                        <p style="color: #5c299e; font-weight: bold;" sq="Maturiteti (muaj)" en="Maturity (months)" sr="Rok dospeća (meseci)"></p>
                                        <input type="range" min="1" max="240" style="width: 300px;" v-model="months" />
                                    </div>
                                    <div class="wrp" style="width: 150px;">
                                        <input id="amount" style="color: #5F4393; font-size: 15px; font-weight: 600" v-model="months">
                                        <span sq="Muaj" en="Months" sr="Meseci"></span>
                                    </div>
                                </div>
                            </div>

                            <div v-if="setActiveTab == 2">
                                <div style="display: flex; justify-content: space-between; align-items: center; gap: 50px;" class="CalcInfo">
                                    <div style="width: 300px;" class="inpWrp">
                                        <p style="color: #5c299e; font-weight: bold;" sq="Shuma e depozituar" en="Amount deposited" sr="Iznos deponovan"></p>
                                        <input type="range" min="500" max="1000000" style="width: 300px;" v-model="amount" />
                                    </div>
                                    <div class="wrp" style="width: 150px;">
                                        <input id="amount" style="color: #5F4393; font-size: 15px; font-weight: 600" v-model="amount">
                                        <span>€</span>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: space-between; align-items: center; gap: 50px; margin-top: 20px; margin-bottom: 20px;" class="CalcInfo">
                                    <div>
                                        <p style="color: #5c299e; font-weight: bold;" sq="Interesi vjetor" en="Annual interests" sr="Godišnje kamate"></p>
                                        <input type="range" min="2" max="20" style="width: 300px;" v-model="amountPercent" />
                                    </div>
                                    <div class="wrp" style="width: 150px;">
                                        <input id="amount" style="color: #5F4393; font-size: 15px; font-weight: 600" v-model="amountPercent">
                                        <span>%</span>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: space-between; align-items: center; gap: 50px;" class="CalcInfo">
                                    <div>
                                        <p style="color: #5c299e; font-weight: bold;" sq="Maturiteti (muaj)" en="Maturity (months)" sr="Rok dospeća (meseci)"></p>
                                        <input type="range" min="1" max="240" style="width: 300px;" v-model="months" />
                                    </div>
                                    <div class="wrp" style="width: 150px;">
                                        <input id="amount" style="color: #5F4393; font-size: 15px; font-weight: 600" v-model="months">
                                        <span sq="Muaj" en="Months" sr="Meseci"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="rightPane">
                        <div v-if="setActiveTab == 1">
                            <div>
                                <p sq="KESTI MUJOR" en="MONTHLY INSTALLMENT" sr="MESEČNA RATA"></p>
                                <div><p>{{calculateKestiMujor}}</p></div>
                            </div>
                            <div style="margin-top: 10px; margin-bottom: 10px;">
                                <p sq="TOTAL INTERESI" en="TOTAL INTEREST" sr="UKUPNO KAMATE"></p>
                                <div><p>{{calculateTotalInteres}}</p></div>
                            </div>
                            <div style="position: relative; color: white" id="appbtn">
                                <router-link to="individual/online-applications?form=14" style="z-index: 2; position: relative; text-decoration: none !important; color: white" sq="Apliko ketu" en="Apply here" sr="Primeniti ovde"></router-link>
                            </div>
                        </div>
                        <div v-if="setActiveTab == 2">
                            <div>
                                <p sq="INTERESI BRUTO" en="GROSS INTEREST" sr="BRUTO KAMATA"></p>
                                <div><p>{{interesiBruto}}</p></div>
                            </div>
                            <div style="margin-top: 10px; margin-bottom: 10px;">
                                <p sq="INTERESI I FITUAR NETO" en="NET INTEREST EARNED" sr="NETO ZARAĐENA KAMATA"></p>
                                <div><p>{{interesiNeto}}</p></div>
                            </div>
                            <div style="position: relative;" id="appbtn">
                                <router-link to="individual/online-applications?form=11" style="z-index: 2; position: relative; text-decoration: none !important; color: white " sq="Apliko ketu" en="Apply here" sr="Primeniti ovde"></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="rate-container">
            <div class="rate-title">
                <h1 sq="Kurset e këmbimit"
                    en="Exchange Rates"
                    sr="PriBank je vaš partner za ostvarivanje vaših želja">
                </h1>
            </div>
           <Rates/>
           <div class="rate-text-section">
                <p sq="*Këto janë kurse orientuese të tregut të cilat mund të jenë subjekt ndryshimesh. Për transaksionet e këmbimit valutor, ju lutem përdorni E-Banking ose kontaktoni zyrtarët e PriBank."
                    en="*These are indicative market rates which may be subject to change. For foreign exchange transactions, please use E-Banking or contact PriBank officials."
                    sr="*Ovo su okvirni kursevi na tržištu koji mogu biti podložni promenama. Za valutne transakcije, molimo vas da koristite E-Banking ili kontaktirate službenike PriBank.">
                </p>
            </div>
        </div>

        <div class="accounts-container" style="margin-top: 10%;">
            <div class="electronic-title">
                <h1 sq="PriBank është partneri juaj për realizimin e dëshirave tuaja"
                    en="PriBank is your partner for realizing your wishes"
                    sr="PriBank je vaš partner za ostvarivanje vaših želja"></h1>
            </div>
            <div class="electronic-section">
                <div class="electronic-post" v-for="item,index in getElectricPosts" :key="item.id">
                    <router-link :to="'individual/post/' + this.getElectricPosts[index].id">
                        <div class="media">
                            <img :src="getPostMedia(getElectricPosts[index]?.featured_media)" alt="Foto e llojit të kredisë" />
                        </div>
                    </router-link>
                    <div class="title">
                        <h1 :sq="item?.meta._sq_post_title" :en="item?.meta._en_post_title" :sr="item?.meta._sr_post_title">  </h1>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>


<style>
    .wrp {
        border: 1px solid rgba(95, 67, 147, 0.35);
        box-shadow: 0px 0px 25px #0000000f;
        padding: 17px 15px;
        color: #5F4393;
        outline: none;
        font-size: 15px;
        font-weight: 500;
        border-radius: 6px;
        width: 50%;
        display: flex;
    }

    #righPane {
        text-align: center;
        height: 300px; 
        width: 500px;
        background-color: #5F4393;
        display: flex; 
        align-items: center;
        padding: 5%; 
        justify-content: center; 
        color: white; 
        font-weight: 800; 
        font-size: 20px;
    }

    .wrp input {
        border: none;
        outline: none;
        width: auto;
        min-width: auto;
        float: left;
        width: 100%;
        color: #18a7
    }

    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 300px;
        height: 2px;
        background: #d3d3d3;
        border-radius: 5px;
        outline: none;
        margin: 10px 0;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        background: #5F4393; 
        border-radius: 50%;
        cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
        width: 20px;
        height: 10px;
        background: #5F4393;
        border: none;
        border-radius: 50%;
        cursor: pointer;
    }

    .shadow {
        -webkit-box-shadow: 0px 0px 18px 1px rgba(204,204,204,1);
        -moz-box-shadow: 0px 0px 18px 1px rgba(204,204,204,1);
        box-shadow: 0px 0px 18px 1px rgba(204,204,204,1);
    }

    #appbtn:hover {
        color: #5F4393 !important;
        transition: all 0.2s linear;
        cursor: pointer;
    }

    #appbtn:hover > a{
        color: #5F4393 !important;
    }

    #appbtn:hover::after {
        background: white;
        height: 30px;
        transition: all 0.2s linear;
    }

    #appbtn::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 2px;
        content: '';
        background: #fff;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }

    .active4 {
        color: rgb(92, 41, 158);
        font-weight: bold;
        border-bottom: 2px solid rgb(92, 41, 158);
    }
    
    @import "../../assets/styles/home.css";
    
</style>

<style>
    #CalculatorWrapper {
        display: flex;
        justify-content: space-between;
        -webkit-box-shadow: 0px 0px 18px 1px rgba(204,204,204,1);
        -moz-box-shadow: 0px 0px 18px 1px rgba(204,204,204,1);
        box-shadow: 0px 0px 18px 1px rgba(204,204,204,1);
    }

    #CalculatorMainContainer{
        display: flex;
        justify-content: center;
    }

    #leftPane {
        width: 700px;
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #rightPane {
        width: 500px;
        height: 400px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        align-items: center;
        background-color: #5f4393;
        color: white;
        column-gap: 30px;
    }

    @media only screen and (max-width: 1199px) {
    .rate-text-section {
     display: flex;
     justify-content: center;
     align-items: center;   
     margin-left: 20rem;
     margin-right: 20rem;
     text-align: center;
     }

        #CalculatorWrapper {
            flex-direction: column !important;
            box-shadow: unset;
        }

        #leftPane {
            height: unset;
            width: unset;
        }

        #rightPane {
            height: unset;
            width: unset;
            padding: 20%;
            margin-top: 30px;
        }
    }

    @media only screen and (max-width: 600px) {
    .rate-text-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px !important;
    }
}
</style>