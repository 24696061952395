<template>
    <div class="rates">
        <table>
            <thead>
                <tr>
                    <th sq="Valuta" en="Currency" sr="Valuta"></th>
                    <th sq="Kursi i shitjes" en="Selling rate" sr="Prodajni kurs"></th>
                    <th sq="Kursi i blerjes" en="Buying rate" sr="Kupovni kurs"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="Rates.length">
                    <td>{{ Rates[0].currency }}</td>    
                    <td>{{ getSellRate(Rates[0].rate) }}</td>
                    <td>{{ getBuyRate(Rates[0].rate) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import xml2js from 'xml2js';

    export default {
        mounted() {
            var xhttp = new XMLHttpRequest();
            let self = this;
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    self.parseXML(this.responseText).then(data => {
                        if (data.length > 0) {
                            self.Rates = [data[0]]; // Set Rates to contain only the first item
                        }
                    }).catch(error => {
                        console.error('Error parsing XML:', error);
                    });
                }
            };
            xhttp.open("GET", "https://cms.pribank-ks.com/bypass.php", true);
            xhttp.send();
        },
        methods: {
            parseXML(data) {
                return new Promise(resolve => {
                    var arr = [],
                        parser = new xml2js.Parser({
                            trim: true,
                            explicitArray: true
                        });
                    parser.parseString(data, function (err, result) {
                        if (err) {
                            console.error('Error parsing XML:', err);
                            return;
                        }
                        var obj = result["gesmes:Envelope"].Cube[0].Cube[0];

                        for (var i in obj.Cube) {
                            var item = obj.Cube[i];
                            arr.push({
                                currency: item.$.currency,
                                rate: parseFloat(item.$.rate)
                            });
                        }
                        resolve(arr);
                    });
                });
            },
            getBuyRate(rate) {
                return (1/((1/rate) * 0.98)).toFixed(4);
            },
            getSellRate(rate) {
                return (1/((1/rate) * 1.02)).toFixed(4);
            }
        },
        data() {
            return {
                Rates: []
            }
        }
    }
</script>

<style scoped>
    @import "../../assets/styles/rates.scss";
</style>
